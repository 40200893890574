<template>
    <VVVLayoutNew>
        <template #page-title>
            Update User Account
        </template>
        <nav aria-label="breadcrumb" style="font-size: 13px;">
            <MDBBreadcrumb>
                <MDBBreadcrumbItem><router-link to="/users">Users</router-link>
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem active style="cursor: default;">
                    Update User Account
                </MDBBreadcrumbItem>
            </MDBBreadcrumb>
        </nav>
        <div class="backdrop" v-if="loadingSpinner">
            <div class="image-container d-flex justify-content-center align-items-center">
                <div class="loader"></div>
                <img src="@/assets/VVV/vvv-loader.png" alt="Loading image">
            </div>
        </div>
        <div class="d-md-flex d-block gap-0 gap-md-3 flex-grow-1 full-height-container">
            <MDBCard id="UserInformation" class="shadow mb-md-0 mb-3 account-form flex-grow-1" tag="form">
                <MDBCardHeader class="py-3">
                    <h5 class="mb-0 fw-bold">Account Information</h5>
                </MDBCardHeader>
                <MDBCardBody class="d-flex flex-column" style="gap: 15px">
                    <div class="d-flex justify-content-center align-items-center mb-2">
                        <div class="default-avatar shadow-lg me-1" v-if="!usersStore.users.profilePictureUrl">
                            <span class="avatar-letter">{{ avatarLetter }}</span>
                        </div>
                        <img v-else :src="usersStore.users.profilePictureUrl" alt="Profile Picture"
                            class="profile-avatar shadow-lg me-1" />
                    </div>
                    <div class="d-flex gap-3">
                        <div class="d-block w-100">
                            <label for="firstName">First Name:</label>
                            <MDBInput id="firstName" class="mt-1" type="text" placeholder="First Name"
                                v-model="usersStore.users.firstName" />
                        </div>
                        <div class="d-block w-100">
                            <label for="lastName">Last Name:</label>
                            <MDBInput id="lastName" class="mt-1" type="text" placeholder="Last Name"
                                v-model="usersStore.users.lastName" />
                        </div>
                    </div>
                    <div>
                        Email:
                        <MDBInput class="mt-1" type="email" placeholder="Email" v-model="usersStore.users.email" />
                    </div>
                    <div>
                        Partner Percentage:
                        <MDBInput class="mt-1" type="number" placeholder="Partner Percentage"
                            v-model="usersStore.users.partnerPercentage" />
                    </div>
                    <div v-if="isUser">
                        Google Sheet Info:
                        <MDBInput label="Google Sheet Link" type="text" v-model="usersStore.users.googleSheetLink" class="mt-3" />
                        <MDBInput label="Google Sheet Name" type="text" v-model="usersStore.users.sheetName" class="mt-4" />
                    </div>
                    <div class="mt-2">
                        <MDBBtn class="fw-bold" type="button" color="primary" aria-controls="assetLabelModal"
                            @click="assetLabelModal = true" size="sm">
                            Select Asset Label/s
                        </MDBBtn>
                        <MDBTable hover class="mt-3">
                            <thead class="table-light align-middle">
                                <tr>
                                    <th v-for="(item, i) in tableHeaders" :key="i" scope="col" class="py-3 px-4">
                                        {{ item.header }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody v-if="usersStore.assetLabels.length < 1">
                                <tr class="align-middle">
                                    <td colspan="3">
                                        No asset label selected.
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else-if="usersStore.assetLabels.length > 0">
                                <tr v-for="(item, i) in usersStore.assetLabels" :key="i">
                                    <td class="align-middle">{{ item.name }}</td>
                                    <td class="align-middle" style="width: 45%;">
                                        <select v-model="item.assignedDashboard" class="form-select">
                                            <option v-for="option in dashboard" :key="option.value"
                                                :value="option.value">
                                                {{ option.text }}
                                            </option>
                                        </select>
                                    </td>
                                    <td class="d-flex justify-content-end text-right p-4">
                                        <MDBBtn color="danger" floating @click.stop.prevent="clickDelete(item)"
                                            size="sm">
                                            <span class="far fa-trash-alt" />
                                        </MDBBtn>
                                    </td>
                                </tr>
                            </tbody>
                        </MDBTable>
                    </div>
                </MDBCardBody>
                <MDBCardFooter class="py-3 d-flex justify-content-end">
                    <MDBBtn class="fw-bold" type="submit" @click="submit" color="primary"
                        :disabled="isUpdateBtnDisabled" size="sm">
                        Update Account
                    </MDBBtn>
                </MDBCardFooter>
            </MDBCard>
            <MDBCard class="shadow account-form flex-grow-1" v-if="isUser">
                <MDBCardHeader class="d-flex justify-content-center align-items-center py-3">
                    <!-- <vue-feather type="youtube" :stroke-width="2.5" class="py-auto my-auto me-2" /> -->
                    <h5 class="mb-0 fw-bold text-center">YouTube Channel List</h5>
                </MDBCardHeader>
                <MDBCardBody class="py-3 px-4 overflow-auto pb-0">
                    <template v-if="ytLoader" class="d-flex justify-content-center align-items-center">
                        <div class="image-container d-flex justify-content-center align-items-center">
                            <div class="loader"></div>
                            <img src="@/assets/VVV/vvv-loader.png" alt="Loading image">
                        </div>
                    </template>
                    <template v-else>
                        <p v-if="youtubeChannelList.length === 0" class="m-auto text-center">No channel found.</p>
                        <div v-else v-for="channel in youtubeChannelList" :key="channel.id"
                            class="d-flex justify-content-between align-items-center py-2">
                            <div class="d-flex align-items-center" style="gap: 10px;cursor: pointer;">
                                <img :src="channel.thumbnail" alt="Thumbnail" style="width: 63px; height: 63px"
                                    class="rounded-circle" />
                                <div>
                                    <p class="my-auto text-left fw-bold" style="font-size: 14px;">{{ channel.channelName
                                        }}
                                    </p>
                                    <p class="my-auto text-left" style="font-size: 13px;">{{ channel.channelUsername }}
                                    </p>
                                    <p class="my-auto text-left" style="font-size: 13px;">{{ channel.subscriberCount }}
                                        subscribers</p>
                                </div>
                            </div>
                            <vue-feather type="trash" :stroke-width="2.5" size="16" class="py-1 px-0 ms-5"
                                style="cursor: pointer;" @click="toggleDeleteModal(channel.id, channel.tableIdentifier)"
                                title="Delete channel" />
                        </div>
                    </template>
                </MDBCardBody>
                <MDBCardFooter class="d-flex justify-content-center">
                    <!-- <router-link :to="{ name: 'NewClaim' }" custom v-slot="{ href, navigate }">
                        <MDBBtn tag="a" color="primary" @click.stop="navigate" :href="href" size="sm" class="fw-bold">
                            Add Channel via New Claim
                        </MDBBtn>
                    </router-link> -->
                    <MDBBtn class="fw-bold" @click="ytChannelModal = true" color="primary" size="sm">
                        Add YouTube Channel
                    </MDBBtn>
                </MDBCardFooter>
            </MDBCard>
        </div>
        <MDBModal id="ytChannelModal" tabindex="-1" labelledby="ytChannelModalLabel" v-model="ytChannelModal" scrollable
            :static-backdrop="true">
            <MDBModalHeader class="px-4">
                <MDBModalTitle class="fw-bold" id="ytChannelModalLabel"> Add YouTube Channel </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody class="px-4">
                <div class="d-flex justify-content-center flex-column">
                    <div class="input-group w-100">
                        <MDBBtn color="primary" size="sm">
                            <i class="fab fa-youtube fa-fw pb-1"></i>
                        </MDBBtn>
                        <input v-model="channelUrl" type="text" class="form-control" placeholder="YouTube Channel URL"
                            @keyup.enter="previewVideo" />
                        <MDBBtn class="btn btn-primary fw-bold" type="button" @click="previewVideo">Preview</MDBBtn>
                    </div>
                </div>
                <template v-if="channelDetails.channelName">
                    <div class="d-flex justify-content-center align-items-center mt-3" v-if="channelLoader">
                        <div class="image-container d-flex justify-content-center align-items-center">
                            <div class="loader"></div>
                            <img src="@/assets/VVV/vvv-loader.png" alt="Loading image">
                        </div>
                    </div>
                    <div v-else class="d-flex flex-column justify-content-center overflow-auto">
                        <div class="d-flex gap-2 mt-3 justify-content-center">
                            <div
                                style="width: 63px; height: 63px; border-radius: 50%; overflow: hidden; display: flex; align-items: center; justify-content: center;">
                                <img :src="channelDetails.thumbnail" alt="Thumbnail"
                                    style="width: auto; height: 100%;" />
                            </div>
                            <div class="my-auto video-details">
                                <p class="my-auto text-left fw-bold" style="font-size: 14px;">{{
                                    channelDetails.channelName }}</p>
                                <p class="my-auto text-left" style="font-size: 13px;">{{
                                    channelDetails.channelUsername }}</p>
                                <p class="my-auto text-left" style="font-size: 13px;">{{ channelDetails.subscriberCount
                                    }} subscribers</p>
                            </div>
                        </div>
                    </div>
                </template>
            </MDBModalBody>
            <MDBModalFooter class="gap-2 py-3 px-4">
                <MDBBtn class="fw-bold" size="sm" @click="ytChannelModal = false"> Close </MDBBtn>
                <MDBBtn class="fw-bold" size="sm" color="primary" @click="submitChannel"
                    :disabled="channelUrl == '' || !isChannel">Confirm Add</MDBBtn>
            </MDBModalFooter>
        </MDBModal>
        <MDBModal id="assetLabelModal" tabindex="-1" labelledby="assetLabelModalLabel" v-model="assetLabelModal"
            scrollable :static-backdrop="true">
            <MDBModalHeader class="px-4">
                <MDBModalTitle class="fw-bold" id="assetLabelModalLabel"> Asset Labels </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody class="pb-0">
                <div>
                    <MDBInput label="Seach Asset Label" class="mb-4" v-model="dataTableSearch" />
                    <MDBDatatable :dataset="usersStore.assetLabelDataTable" selectable multi :search="dataTableSearch"
                        @selected-rows="handleSelectedRow($event)" hover />
                </div>
            </MDBModalBody>
            <MDBModalFooter class="gap-2 py-3 px-4">
                <MDBBtn class="fw-bold" size="sm" @click="assetLabelModal = false"> Close </MDBBtn>
                <MDBBtn class="fw-bold" size="sm" color="primary" @click="assetLabelModal = false">
                    Select Asset Labels
                </MDBBtn>
            </MDBModalFooter>
        </MDBModal>
        <MDBModal id="deleteModal" tabindex="-1" labelledby="deleteModalLabel" v-model="deleteModal">
            <MDBModalHeader>
                <MDBModalTitle class="fw-bold" id="deleteModalLabel"> Delete Channel </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>Are you sure you want to delete this channel?</MDBModalBody>
            <MDBModalFooter class="gap-2">
                <MDBBtn class="btn fw-bold" size="sm" @click="deleteModal = false">Close</MDBBtn>
                <MDBBtn class="btn btn-danger fw-bold" size="sm" @click="deleteChannel">Confirm Delete</MDBBtn>
            </MDBModalFooter>
        </MDBModal>
        <MDBToast v-model="toastObject.state" :delay="2000" autohide position="top-right" appendToBody stacking
            width="350px" :color="toastObject.color" text="white" :icon="toastObject.icon">
            <template #title>
                {{ toastObject.title }}
            </template>
            {{ toastObject.message }}
        </MDBToast>
        <MDBToast v-model="errorToast" :delay="2000" autohide position="top-right" appendToBody stacking width="350px"
            color="danger" text="white" icon="fas fa-check fa-lg me-2">
            <template #title> Error </template>
            {{ errorToastMessage }}
        </MDBToast>
    </VVVLayoutNew>
</template>

<script setup>
import { MDBBtn, MDBCard, MDBCardHeader, MDBCardBody, MDBCardFooter, MDBModal, MDBModalHeader, MDBModalBody, MDBModalTitle, MDBModalFooter, MDBDatatable, MDBInput, MDBTable, MDBBreadcrumb, MDBBreadcrumbItem, MDBToast } from "mdb-vue-ui-kit";
import VVVLayoutNew from "@/views/VVVNew/VVVLayoutNew.vue";
import { onMounted, ref, watch, computed } from "vue";
import axios from "axios";
import { useUsersStore } from "@/store/usersStore";
import { useRoute } from "vue-router";
import { useTitle } from "@vueuse/core";
import { storeToRefs } from "pinia";
import VueFeather from 'vue-feather';
import { GetUserSocialMediaPages } from "@/services/UserSocialMediaPages/GetUserSocialMediaPages";
import { DeleteUserSocialMediaPages } from "@/services/UserSocialMediaPages/DeleteUserSocialMediaPages";
import { isYouTubeUrl, fetchChannelDetailsDirectly, fetchChannelDetailsBatch } from "@/helpers/youtubeScraper";
import { useYoutubeStore } from "@/store/youtubeStore";
import { PostUserSocialMediaPages } from "@/services/UserSocialMediaPages/PostUserSocialMediaPages";

useTitle("Update User Account | Very Viral Videos");

const usersStore = useUsersStore();
const { isUser } = storeToRefs(usersStore);
const route = useRoute();
const userId = route.params.id;
const assetLabelModal = ref(false);

const tableLoader = ref(false);
const dataTableSearch = ref("");

onMounted(async () => {
    tableLoader.value = true;
    await axios
        .get("api/assetLabels/Dropdown")
        .then(function (data) {
            usersStore.assetLabelDataTable.rows = data.data;
            usersStore.getUsers({ UserId: userId });
            usersStore.getAssetLabels({ UserId: userId });
            tableLoader.value = false;
        })
        .catch(function (error) {
            toastObject.value.state = true;
            toastObject.value.message = "An error occurred during request" + error;
            toastObject.value.color = "danger";
            toastObject.value.icon = "fas fa-exclamation-circle fa-lg me-2";
        });
});

const handleSelectedRow = (selectedModalArray) => {
    const newArray = [
        ...new Set([...usersStore.assetLabels, ...selectedModalArray]),
    ];
    usersStore.assetLabels = newArray;
};

const clickDelete = (item) => {
    const newArray = usersStore.assetLabels.filter(
        (element) => element.id != item.id
    );
    usersStore.assetLabels = newArray;
};

const toastObject = ref({
    state: false,
    message: "",
    color: "",
    icon: "",
    title: "",
});

let loadingSpinner = ref(false);

const selectedAssetLabels = ref("");
watch([selectedAssetLabels], ([newSelectedAssetLabels]) => {
    usersStore.assetLabels = newSelectedAssetLabels.split(",");
});

const tableHeaders = computed(() => [
    {
        header: "Name",
    },
    {
        header: "Dashboard",
    },
    {},
]);

const isUpdateBtnDisabled = computed(() => usersStore.users.email == "");

const submit = () => {
    event.preventDefault();
    const formData = {
        firstName: usersStore.users.firstName,
        lastName: usersStore.users.lastName,
        email: usersStore.users.email,
        userTypeId: getUserTypeId.value,
        assignedAssetLabels: usersStore.assetLabels.map(label => ({
            ...label,
            assignedDashboard: label.assignedDashboard
        })),
        partnerPercentage: usersStore.users.partnerPercentage,
        id: usersStore.users.id,
        googleSheetLink: usersStore.users.googleSheetLink != "" ? usersStore.users.googleSheetLink : null,
        sheetName: usersStore.users.sheetName != "" ? usersStore.users.sheetName : null,
    };

    loadingSpinner.value = true;
    axios
        .put("api/users", formData)
        .then(function (response) {
            if (response.status == 200 || response.status == 201) {
                toastObject.value.title = "Success!";
                toastObject.value.state = true;
                toastObject.value.message = "Account information updated successfully!";
                toastObject.value.color = "success";
                toastObject.value.icon = "fas fa-check fa-lg me-2";
                loadingSpinner.value = false;
            } else {
                toastObject.value.title = "Error!";
                toastObject.value.state = true;
                toastObject.value.message = "An error occurred during request.";
                toastObject.value.color = "danger";
                toastObject.value.icon = "fas fa-exclamation-circle fa-lg me-2";
                loadingSpinner.value = false;
            }
        })
        .catch(function (error) {
            toastObject.value.title = "Error!";
            toastObject.value.state = true;
            toastObject.value.message = "An error occurred during request: " + error;
            toastObject.value.color = "danger";
            toastObject.value.icon = "fas fa-exclamation-circle fa-lg me-2";
            loadingSpinner.value = false;
        });
};

// const getUserTypeId = computed(() => (usersStore.users.isDistributor ? 3 : 2));
const getUserTypeId = computed(() => {
    if (usersStore.users.userTypeId == 4) {
        return 4;
    } else if (usersStore.users.userTypeId == 1) {
        return 1;
    } else {
        return 3;
    }
});

const dashboard = ref([
    { text: 'Creator Shield & Very Viral Videos', value: 1 },
    { text: 'Creator Shield', value: 2 },
    { text: 'Very Viral Videos', value: 3 },
]);

const avatarLetter = computed(() => {
    return usersStore.users?.firstName?.charAt(0).toUpperCase() || '';
});

watch(() => usersStore.users, (newUser) => {
    if (isUser.value) {
        getYoutubeChannelInformationList(newUser.id);
    }
});

const youtubeChannelList = ref([]);
const ytLoader = ref(false);

const getYoutubeChannelInformationList = async (userId) => {
    ytLoader.value = true;
    try {
        const response = await GetUserSocialMediaPages(userId);
        const channels = response.data;

        const channelIds = channels.map((channel) => channel.channelId);

        const detailedChannels = await fetchChannelDetailsBatch(channelIds);

        youtubeChannelList.value = channels.map((channel) => {
            const details = detailedChannels.find(detail => detail.channelId === channel.channelId);
            return {
                ...channel,
                ...details,
            };
        });

        ytLoader.value = false;
    } catch (error) {
        console.error("Error fetching YouTube channel information:", error);
        ytLoader.value = false;
    }
};

const deleteModal = ref(false);
const channel = ref("");
const channelTableIdentifier = ref("");

const toggleDeleteModal = (id, tableIdentifier) => {
    deleteModal.value = true;
    channel.value = id;
    channelTableIdentifier.value = tableIdentifier;
};

const deleteChannel = async () => {
    try {
        await DeleteUserSocialMediaPages(channel.value, channelTableIdentifier.value);
        getYoutubeChannelInformationList(usersStore.users.id);
        deleteModal.value = false;
    } catch (error) {
        console.error("Error deleting channel:", error);
        errorToastMessage.value = "An error occurred while deleting channel.";
        errorToast.value = true;
    }
};

const youtubeStore = useYoutubeStore();
const { youtubeVideo } = storeToRefs(youtubeStore);

const ytChannelModal = ref(false);
const channelUrl = ref(youtubeVideo.value.url);
const channelLoader = ref(false);

const errorToast = ref(false);
const errorToastMessage = ref("Error");

const channelDetails = ref({
    channelId: "",
    channelName: "",
    channelUsername: "",
    thumbnail: "",
    subscriberCount: ""
});

const isChannel = ref(false);
const previewVideo = async () => {
    if (channelUrl.value === "") {
        errorToastMessage.value = "Please enter a YouTube URL to preview.";
        errorToast.value = true;
        return;
    }
    if (!isYouTubeUrl(channelUrl.value)) {
        errorToastMessage.value = "Please enter a valid YouTube URL.";
        errorToast.value = true;
        return;
    }

    channelLoader.value = true;

    try {
        const channelData = await fetchChannelDetailsDirectly(channelUrl.value);
        channelDetails.value = {
            channelId: channelData.channelId,
            channelName: channelData.channelName,
            channelUsername: channelData.channelUsername,
            thumbnail: channelData.thumbnail,
            subscriberCount: channelData.subscriberCount
        };
        channelLoader.value = false;
        isChannel.value = true;
    } catch (error) {
        isChannel.value = false;
        console.error("Error fetching channel details:", error);
        errorToastMessage.value = "Failed to load channel details. Please try again.";
        errorToast.value = true;
        channelLoader.value = false;
    }
};

const submitChannel = async () => {
    try {
        const channelInfo = {
            UserId: usersStore.users.id,
            Link: channelUrl.value,
            ChannelId: channelDetails.value.channelId,
            SocialMediaPlatformId: 4,
        };

        const submitResponse = await PostUserSocialMediaPages(channelInfo);

        if (submitResponse === 1) {
            errorToastMessage.value = "YouTube channel already exists.";
            errorToast.value = true;
        } else if (submitResponse) {
            ytChannelModal.value = false;
            channelDetails.value = {};
            channelUrl.value = "";
            toastObject.value.title = "Success!";
            toastObject.value.state = true;
            toastObject.value.message = "YouTube channel added successfully.";
            toastObject.value.color = "success";
            toastObject.value.icon = "fas fa-check fa-lg me-2";
            getYoutubeChannelInformationList(usersStore.users.id);
        } else {
            errorToastMessage.value = "Failed to submit channel.";
            errorToast.value = true;
        }
    } catch (error) {
        console.error("Error submitting channel:", error);
        errorToastMessage.value = "Failed to submit channel.";
        errorToast.value = true;
    }
};
</script>

<style>
:root {
    --white: #ffffff;
    --primary: #223698;
    --secondary: #e2e6ef;
    --accent: #02061b;
}
</style>

<style scoped>
.btn-primary {
    background-color: var(--primary);
}

.btn-tertiary {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: var(--accent);
    border-color: var(--accent);
}

.card,
.card-body {
    overflow-y: auto;
}

.image-container {
    position: relative;
    display: inline-block;
}

.image-container .loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 60px;
    height: 60px;
    border: 3px solid rgba(0, 0, 0, 0.1);
    border-top-color: rgb(153, 153, 153);
    border-radius: 50%;
    animation: BorderSpin 1s linear infinite;
}

.image-container img {
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

@keyframes BorderSpin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.account-form {
    width: 50%
}

.button-title {
    width: 17%;
}

.social-title {
    width: 25%;
}

.social-link {
    width: 75%;
}

@media screen and (max-width: 767px) {
    .button-title {
        width: fit-content;
    }

    .account-form {
        width: 100%
    }

    .social-title {
        width: 45%;
    }

    .social-link {
        width: 55%;
    }
}

.default-avatar {
    width: 60px;
    height: 60px;
    background-color: var(--primary);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 30px;
    font-weight: bold;
}

.profile-avatar {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    object-fit: cover;
}

.avatar-letter {
    text-transform: uppercase;
}

.full-height-container {
    height: calc(100vh - 120px);
}
</style>
